<template>
    <div>
       <loading-overlay :active="apiStateLoading" :is-full-page="true" loader="bars"/>
       <CModalExtended
           :title="tituloModal"
           color="dark"
           :closeOnBackdrop="false"
           :show.sync="$store.state.ubicacion.modalParroquia"
       >
           <CRow>                
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.country')"
                       addLabelClasses="required text-right"
                       :value="paisSelected"
                       v-model.trim="$v.paisSelected.$model"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       :options="optionsListPaises"
                       :is-valid="hasError($v.paisSelected)"
                       :invalid-feedback="errorMessage($v.paisSelected)"
                   >
                   </CSelect>
               </CCol>
           </CRow>
           <CRow>
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.state')"
                       addLabelClasses="required text-right"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       v-model.trim="$v.estadoSelected.$model"
                       :options="optionsListEstado"
                       :is-valid="hasError($v.estadoSelected)"
                       :invalid-feedback="errorMessage($v.estadoSelected)"
                   >
                   </CSelect>
               </CCol>
           </CRow>
           <CRow>
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.municipality')"
                       addLabelClasses="required text-right"
                       v-model.trim="$v.formParroquia.IdMunicipio.$model"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       :addInputClasses="{ 'is-invalid': $v.formParroquia.IdMunicipio.$error }"
                       :options="optionsListMunicipio"
                       :is-valid="hasError($v.formParroquia.IdMunicipio)"
                       :invalid-feedback="errorMessage($v.formParroquia.IdMunicipio)"
                   >
                   </CSelect>                          
               </CCol>
           </CRow>
           <CRow>
               <CCol sm="12" lg="12">
                   <CInput
                       :label="$t('label.name')"
                       addLabelClasses="required text-right"
                       :placeholder="$t('label.parishName')"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       v-uppercase
                       v-model="$v.formParroquia.NbParroquia.$model"
                       required
                       :invalid-feedback="errorMessage($v.formParroquia.NbParroquia)"
                       :is-valid="hasError($v.formParroquia.NbParroquia)" 
                   >
                   </CInput>
               </CCol>
           </CRow>
           <CRow>
               <CCol sm="12" lg="12">
                   <CSelect
                       :label="$t('label.status')"
                       addLabelClasses="required text-right"
                       :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                       :is-valid="formParroquia.FgActParroquia"
                       v-if="!idIndentification"
                       v-model="formParroquia.FgActParroquia"
                       :value.sync="formParroquia.FgActParroquia"
                       :options="selectOptions"
                   />
               </CCol>
           </CRow>
           <CElementCover 
                :opacity="0.8"
               v-if="loading"
           >
               <label class="d-inline">{{$t('label.load')}}... </label>
               <CSpinner size="sm" />
           </CElementCover>
           <div slot="footer">
               <CButton
                   color="add"
                   class="m-1 ml-2"
                   @click="registerData"
                   outline 
                   :disabled="isSubmit"
               >
                   <div>
                       <CIcon name="checkAlt"/>&nbsp;
                       {{$t('button.accept')}}
                   </div>
               </CButton>
               <CButton
                   color="wipe"
                   @click="cerrarModal"
                   class="m-1"
                   :disabled="apiStateFormLoading || loading "
               >
               <CIcon name="x"/>&nbsp;
                   {{$t('button.cancel')}}
               </CButton>
           </div>
       </CModalExtended>
   </div>
</template>
<script>
   import ENUM from '@/_store/enum';
   import { FormParroquia } from '@/_validations/ubicacion/UbicacionValidation';
   import { mapState,mapMutations } from 'vuex';
   import General from "@/_mixins/general";
   import ModalMixin from "@/_mixins/modal";
   import UpperCase  from '@/_validations/uppercase-directive';
   //data
   function data() {
       return {
           srcError:'/img/errorimage.jpg',
           formParroquia:{
               IdParroquia:0,
               NbParroquia:'',
               IdMunicipio:'',
               FgActParroquia:true,
           },            
           originalAct:'',
           originalEstado:'',
           originalMunicipio:'',
           estadoById:'',
           municipioById:'',
           paisSelected:'',
           estadoSelected:'',
           MydataEstado:[],
           MydataMunicipio:[],
           loading:false,
           apiStateLoading:false,
           tituloModal:'',
           isSubmit:false
       }
   }

   //methods
   function cambio(e){
       this.formParroquia.FgActParroquia = !this.formParroquia.FgActParroquia;
   }
   function cerrarModal(){
       if(this.apiStateFormLoading || this.loading)
           return
       this.$store.commit('ubicacion/mutationModalParroquia', false);
   }
   function validURL(str) {
       var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
       '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
       '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
       '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
       return !!pattern.test(str);
   }
   function registerData(){
       try {
           this.isSubmit=true;
           this.apiStateLoading = true;
           this.$v.$touch();
           if (this.$v.$error){
               throw this.$t('label.errorsPleaseCheck');
           }
           let { IdParroquia,IdMunicipio,NbParroquia,FgActParroquia } = this.formParroquia;
           const IdPais = this.paisSelected;
           const IdEstado = this.estadoSelected;
           const { dispatch } = this.$store;
           if(this.originalAct !== '' && IdParroquia !== 0){
               if(this.originalAct !== FgActParroquia){                
                       this.$swal
                       .fire(this.alertProperties({
                           text: `${this.$t('label.changeStatusQuestion')+' '} ${this.NbParroquia}?`,
                       }))
                       .then((result) => {
                       if (result.isConfirmed) {
                           dispatch('ubicacion/postregisterParroquia', { IdPais,IdEstado,IdParroquia,IdMunicipio,NbParroquia,FgActParroquia });
                       }else {
                           // this.$swal('Operación cancelada');
                       }
                   })
               }else{
                   dispatch('ubicacion/postregisterParroquia', { IdPais,IdEstado,IdParroquia,IdMunicipio,NbParroquia,FgActParroquia });
                   this.isSubmit=false;
                   this.apiStateLoading=false;
               }
           }else{
               dispatch('ubicacion/postregisterParroquia', { IdPais,IdEstado,IdParroquia,IdMunicipio,NbParroquia,FgActParroquia });
               this.isSubmit=false;
               this.apiStateLoading=false;
            }
       }  catch (error) {
           this.isSubmit=false;
           this.apiStateLoading=false;
           this.notifyError({text: error});
       }
       
   }
   function setAltImg(event){
       event.target.src = this.srcError;
   }
   
   //computed
   function selectOptions() {
       return [        
               { 
                   value: true, 
                   label: this.$t('label.ACTIVO')
               },
               { 
                   value: false, 
                   label: this.$t('label.INACTIVO')
               }
           ]
       }
   function idIndentification(){
       return this.formParroquia.IdParroquia === 0;
   }
   function IdPaisSelected(){
       return this.paisSelected !== "";
   }
   function apiStateFormLoading() {
       let carga = false;
       if(this.apiStateForm === ENUM.LOADING){
           carga = !carga;
       }
       return carga;
   }
   
   function SvgIcon(){
       if(this.paisSelected !== ''){
           if(this.myDataPais.length !== 0){
               const x  = this.myDataPais.map(function(e){
                   return e.CountryId;
               }).indexOf(this.paisSelected);
               if(x !== -1){
                   if(this.myDataPais[x].Icon === null || this.myDataPais[x].Icon === '' || this.myDataPais[x].Icon === undefined){
                       return this.srcError;
                   }   
                   return this.validURL(this.myDataPais[x].Icon) ? this.myDataPais[x].Icon : `${this.$store.getters["connection/getBase"]}${this.myDataPais[x].Icon.replace('public/','', null, 'i')}`;
               }else{
                   return this.srcError;
               }
           }
       }
       return this.srcError;
   }


   function optionsListPaises(){
       if(this.myDataPais.length === 0){
           return [{
               value: '', 
               label: this.$t('label.select')
           }];
       }else{
           const chart = [{
               value: '', 
               label: this.$t('label.select')
           }];
           this.myDataPais.map(function(e){
               if(e.FgActCountry){
                   chart.push({
                       value: e.CountryId, 
                       label: e.CountryName,
                   })
               }
           })
           return chart;
       }
   }

   function optionsListEstado(){
       if(this.MydataEstado.length === 0){
           return [{
               value: '', 
               label: this.$t('label.select')
           }];
       }else{
           const chart = [{
               value: '', 
               label: this.$t('label.select')
           }];
           this.MydataEstado.map(function(e){
               if(e.FgActState){
                   chart.push({
                       value: e.StateId, 
                       label: e.StateName,
                   })
               }
           })
           return chart;
       }
   }

   function optionsListMunicipio(){
       if(this.MydataMunicipio.length === 0){
           return [{
               value: '', 
               label: this.$t('label.select')
           }];
       }else{
           const chart = [{
               value: '', 
               label: this.$t('label.select')
           }];
           this.MydataMunicipio.map(function(e){
               if(e.FgActMunicipality){
                   chart.push({
                       value: e.MunicipalityId, 
                       label: e.MunicipalityName,
                   })
               }
           })
           return chart;
       }
   }

   //watch
   function modalParroquia(newQuestion,Oldquestion){
       if(newQuestion === false){
           this.tituloModal = '';
           this.paisSelected = '';
           this.estadoSelected = '';
           this.formParroquia.IdMunicipio='';
           this.formParroquia.IdParroquia=0;
           this.formParroquia.NbParroquia='';
           this.originalAct = '';
           this.formParroquia.FgActParroquia=true;
           this.isSubmit=false;
           this.isSubmit=false;
           this.apiStateLoading=false;
           this.$nextTick(() => { this.$v.$reset() })
           this.$store.commit('ubicacion/asignarid', 0);
       }else{
           if(this.idState === 0){
               this.paisSelected = this.filtroPais;
               this.originalEstado = this.filtroEstado;
               this.originalMunicipio=this.filtroMunicipio;
               this.tituloModal = this.$t('label.nueva')+this.$t('label.parish');
           }
       }
   }
   function idState(newQuestion,Oldquestion){
       if(this.modalParroquia){
           if(newQuestion !== 0){
               //this.$store.state.ubicacion.apiStateForm = ENUM.LOADING;
               this.apiStateLoading=true;
               this.$http.ejecutar('GET', 'Parish-by-id',{ ParishId: newQuestion }, '' ).then(response => {
                   if(response.status === 200){
                       const parroquiaInformation = response.data.data;
                       if(parroquiaInformation.length !== 0){
                           this.tituloModal = this.$t('label.edit')+this.$t('label.parish')+": "+parroquiaInformation[0].ParishName;
                           this.paisSelected = parroquiaInformation[0].CountryId;
                           this.formParroquia.IdParroquia = parroquiaInformation[0].ParishId;
                           this.formParroquia.NbParroquia = parroquiaInformation[0].ParishName;
                           this.originalAct = parroquiaInformation[0].FgActParish;
                           this.formParroquia.FgActParroquia = parroquiaInformation[0].FgActParish;
                           this.estadoById = parroquiaInformation[0].StateId;
                           this.municipioById = parroquiaInformation[0].MunicipalityId;
                           //this.$store.state.ubicacion.apiStateForm = ENUM.INIT;
                           this.apiStateLoading=false;
                           this.$v.$touch();
                       }else{
                           this.apiStateLoading=false;
                          //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                           this.$store.commit('ubicacion/mutationModalParroquia', false);
                       }
                   }else{
                       this.apiStateLoading=false;
                       //this.$store.state.ubicacion.apiStateForm = ENUM.ERROR;
                       this.$store.commit('ubicacion/mutationModalParroquia', false);
                   }
               }).catch(err => {
                   this.apiStateLoading=false;
                   //this.$store.state.ubicacion.apiState = ENUM.ERROR;
                   this.$store.commit('ubicacion/mutationModalParroquia', false);
               });
           }
       }
   }
   function paisSelected(newQuestion,Oldquestion){
       if(newQuestion !== "" && typeof newQuestion == 'string' ){
           this.estadoSelected = this.originalEstado;
           this.originalEstado = '';
           this.MydataEstado = [];
           this.apiStateLoading=true;
           let idPais = newQuestion;
           this.$http.ejecutar('GET', 'State-list',  { Filter: 'ALL', CountryId:idPais } , '')
           .then(
               Estados => {
                   try{
                       if(Estados.status === 200){
                           this.apiStateLoading = false;
                           this.MydataEstado = Estados.data.data;
                           if(this.idState !== 0){
                               this.estadoSelected = this.estadoById;
                           }
                           this.estadoById = this.estadoById !== '' ? '' : this.estadoById
                       }else{
                           this.apiStateLoading = false;
                       }
                   }catch(error){
                       this.apiStateLoading = false;
                       this.$store.commit('ubicacion/getParroquialistFailure', error)
                   }
               },
               error => {
                   this.apiStateLoading = false;
                   this.$store.commit('ubicacion/getParroquialistFailure', error)
               }
           );
       }else{
           this.estadoSelected = '';
           this.MydataEstado = [];
       }
   }

   function estadoSelected(newQuestion,Oldquestion){
       if(newQuestion !== "" && typeof newQuestion == 'string' ){
           this.formParroquia.IdMunicipio = this.originalMunicipio;
           this.originalMunicipio = '';
           this.MydataMunicipio = [];
           this.apiStateLoading = true;
           let idEstado = newQuestion;
           this.$http.ejecutar('GET', 'Municipality-list',  { Filter: 'ALL', StateId:idEstado } , '')
           .then(
               Municipios => {
                   try{
                       if(Municipios.status === 200){
                           this.apiStateLoading = false;
                           this.MydataMunicipio = Municipios.data.data;
                           if(this.idState !== 0){
                               this.formParroquia.IdMunicipio = this.municipioById;
                           }
                           this.municipioById = this.municipioById !== '' ? '' : this.municipioById
                       }else{
                           this.apiStateLoading = false;
                       }
                   }catch(error){
                       this.apiStateLoading = false;
                       this.$store.commit('ubicacion/getParroquialistFailure', error)
                   }
               },
               error => {
                   this.apiStateLoading = false;
                   this.$store.commit('ubicacion/getParroquialistFailure', error)
               }
           );
       }else{
           this.formParroquia.IdMunicipio = '';
           this.MydataMunicipio = [];
       }
   }

   export default {
       name: 'app-modal-parroquia',
       data,
       mixins:[General,ModalMixin],
       validations(){ return FormParroquia() },
       methods:{
           validURL,
           cambio,
           setAltImg,
           registerData,
           cerrarModal
       },
       directives: UpperCase,
       computed:{
           selectOptions,
           idIndentification,
           optionsListPaises,
           IdPaisSelected,
           SvgIcon,
           optionsListMunicipio,
           optionsListEstado,
           apiStateFormLoading,
           ...mapState({
               idState: state=> state.ubicacion.id,
               modalParroquia: state=> state.ubicacion.modalParroquia,
               apiState: state => state.ubicacion.apiState,
               apiStateForm: state => state.ubicacion.apiStateForm,
               myDataPais: state => state.ubicacion.myDataPais,
               filtroPais: state => state.ubicacion.filtroPais,
               filtroEstado: state => state.ubicacion.filtroEstado,
               filtroMunicipio: state => state.ubicacion.filtroMunicipio
           })
       },
       watch:{
           idState,
           paisSelected,
           estadoSelected,
           modalParroquia
       }
   }
</script>